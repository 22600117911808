import dynamic from 'next/dynamic';
import { useTranslation } from '../../lib/i18n';
import { trackEvent } from '../../lib/tracking';
import Grid from '../common/grid';
import List from '../common/list';
import Loading from '../common/loading';
import { FadeIn } from '../common/transitions';
import FilterContextProvider, {
  allCategories,
  useFilter,
} from '../filter/filter-context';
import {
  CurrentFilter,
  DesktopFilterButton,
  FilterSort,
  MobileFilterButton,
} from './filter-actions';
import FilterForm from './form';
const DynamicFilterDialog = dynamic(() => import('./dialog'));

const ListWithTransition = (props) => (
  <FadeIn appear={true} show={true} duration='duration-500'>
    <List {...props} />
  </FadeIn>
);

const Inner = ({ children, Wrapper = Grid, className = '' }) => {
  const {
    showFilterDialog,
    state: { results, loading, resultCount, filterUsed },
  } = useFilter();
  const { t } = useTranslation();

  const onFilter = () => {
    trackEvent({
      category: 'filter',
      action: 'show',
    });
    showFilterDialog('wein');
  };

  Wrapper = filterUsed ? Grid : Wrapper;

  return (
    <>
      <DynamicFilterDialog />
      <div
        className={`${className} grid-cols-[minmax(170px,12rem)_minmax(500px,auto)] gap-10 lg:grid`}
      >
        <div className='filter-form-compact hidden flex-col lg:flex'>
          <FilterForm compact />
          <DesktopFilterButton onClick={onFilter} />
        </div>

        <div>
          {children ?? <>{children}</>}
          <div className='flex items-center justify-between pb-10'>
            <MobileFilterButton
              onClick={onFilter}
              className='block lg:hidden'
            />

            <CurrentFilter
              className='mr-4 hidden items-center gap-4 overflow-auto lg:flex'
              updateImmediately
            />
            <FilterSort />
          </div>

          {loading ? (
            <Loading />
          ) : resultCount === 0 ? (
            <div className='ml-10'>{t('Keine Resultate gefunden')}</div>
          ) : Array.isArray(results) ? (
            <ListWithTransition
              items={results}
              Wrapper={Wrapper}
              listName={'filter'}
            />
          ) : (
            <>
              {allCategories.map((category, index) => {
                const items = results[category]?.results;
                if (items && items.length > 0) {
                  return (
                    <div key={index} className={index > 0 ? 'mt-16' : ''}>
                      <h2 className='mb-4 text-center text-base lg:ml-12 lg:text-left'>
                        {t('search_' + category)}
                      </h2>

                      <ListWithTransition items={items} listName={'search'} />
                    </div>
                  );
                }
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export const FilterContainer = ({ defaultResults, ...props }) => (
  <FilterContextProvider defaultResults={defaultResults}>
    {/* @ts-ignore */}
    <Inner {...props} />
  </FilterContextProvider>
);
