import 'rc-slider/assets/index.css';

import RcSlider from 'rc-slider';
import { useEffect, useState } from 'react';

export const SLIDER_STYLES = {
  handleStyle: {
    backgroundColor: '#fff',
    height: '16px',
    width: '16px',
    border: '2px solid #688F68',
    marginTop: '-5px',
  },

  handleStyleSmall: {
    backgroundColor: '#fff',
    height: '12px',
    width: '12px',
    border: '1px solid #688F68',
    marginTop: '-6px',
  },

  trackStyle: {
    height: 6,
    backgroundColor: '#688F68',
    opacity: 0.75,
    borderRadius: 0,
  },

  railStyle: { backgroundColor: '#000', height: 6, opacity: 0.1 },
  dotStyle: {
    backgroundColor: '#FBF9F2',
    height: 8,
    borderColor: 'none',
    border: 'none',
    borderRadius: 0,
    bottom: 'auto',
    marginLeft: 0,
    width: 0,
  },
  markerStyle: {
    color: '#3E2D2A',
    marginTop: -37,
  },
};

export const SLIDER_MARKS = {
  1: { style: SLIDER_STYLES.markerStyle, label: 1 },
  2: { style: SLIDER_STYLES.markerStyle, label: 2 },
  3: { style: SLIDER_STYLES.markerStyle, label: 3 },
  4: { style: SLIDER_STYLES.markerStyle, label: 4 },
  5: { style: SLIDER_STYLES.markerStyle, label: 5 },
};

export const DEFAULT_VALUE = 3;

export const Slider = ({
  inclusiveMin = 1,
  inclusiveMax = 5,
  marks = SLIDER_MARKS,
  minLabel = '',
  maxLabel = '',
  initValue = DEFAULT_VALUE,
  defaultValue = DEFAULT_VALUE,
  onChange = null,
  className = '',
  labelCentered = true,
}) => {
  const [value, setValue] = useState(defaultValue);

  const onSliderChange = (newValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  const minus = (e) => {
    e.stopPropagation();
    if (value <= inclusiveMin) return;
    onChange(value - 1);
    setValue(value - 1);
  };
  const plus = (e) => {
    e.stopPropagation();
    if (value >= inclusiveMax) return;
    onChange(value + 1);
    setValue(value + 1);
  };

  useEffect(() => {
    if (initValue != value) {
      setValue(initValue);
    }
  }, [initValue]);

  return (
    <div className={`${className} w-full pt-6`}>
      <RcSlider
        defaultValue={defaultValue}
        min={inclusiveMin}
        max={inclusiveMax}
        marks={marks}
        dots={true}
        value={value}
        dotStyle={SLIDER_STYLES.dotStyle}
        activeDotStyle={SLIDER_STYLES.dotStyle}
        onChange={onSliderChange}
        railStyle={SLIDER_STYLES.railStyle}
        handleStyle={SLIDER_STYLES.handleStyle}
        trackStyle={SLIDER_STYLES.trackStyle}
      />
      {minLabel && maxLabel && (
        <div
          className={`${
            labelCentered ? ' -mx-2' : ''
          } mt-2 flex justify-between`}
        >
          <div
            className={`${
              labelCentered ? 'w-16' : 'w-1/3'
            } cursor-pointer text-left text-sm`}
            onClick={minus}
          >
            {minLabel}
          </div>
          <div
            className={`${
              labelCentered ? 'w-16' : 'w-1/3'
            } cursor-pointer text-right text-sm`}
            onClick={plus}
          >
            {maxLabel}
          </div>
        </div>
      )}
    </div>
  );
};
