const Grid = ({ className = '', children }) => {
  return (
    <div
      // TODO: could be  -mx-9
      className={`grid grid-cols-1 justify-start md:grid-cols-2 lg:grid-cols-3 ${className} -mx-8 gap-y-14 md:gap-y-12`}
    >
      {children}
    </div>
  );
};

export default Grid;
